import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import get from "dlv";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, title }) {
  const { siteSettings } = useStaticQuery(
    graphql`
      query {
        siteSettings {
          seoTitle
          openGraphImage
          seoDescription
        }
      }
    `
  );

  const metaTitle = get(siteSettings, "seoTitle");

  const metaDescription = description || get(siteSettings, "seoDescription");
  const ogImage = get(siteSettings, "openGraphImage");

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      titleTemplate={title && `%s | ${metaTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title || metaTitle,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
    >
      <title itemProp="name" lang="en">
        {title || metaTitle}
      </title>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en-GB`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
